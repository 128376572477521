import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../context/Auth.context.jsx';
import useLogin from '../../hooks/query/useLogin.js';

const ProtectedRoute = ({ redirectPath = '/', children }) => {
  const { userData } = useAuth();
  useLogin({}, !userData || !Object.keys(userData).length);
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App.jsx';
import ComingSoon from './ComingSoon.jsx';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './context/Auth.context.jsx';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute.jsx';
import Events from './components/Events/Events.jsx';
import Event from './components/Event/Event.jsx';
import ItemsList from './components/Event/ItemsList.jsx';
import Slideshow from './components/Event/Slideshow.jsx';
import './index.css';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<ComingSoon />} />
          <Route element={<ProtectedRoute />}>
            <Route path='app/events' element={<Events />} />
            <Route path='app/event/:eventCode' element={<Event />}>
              <Route index element={<ItemsList />} />
              <Route path='slideshow' element={<Slideshow />} />
            </Route>
          </Route>
          <Route path="*" element={() => <div>404 PAGE NOT FOUND</div>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

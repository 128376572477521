import { GET, SOURCE } from '../components/lib/communication';

export const getEvents = async () => {
  return GET('/events?fullData=false');
};

export const getEventBy = async by => {
  return GET(`/events/by?fullData=true&type=${Object.keys(by)[0]}&value=${Object.values(by)[0]}`);
};

export const getEventFeed = async eventId => {
  return SOURCE(`/events/${eventId}/items?live=true`);
};
import React, { useState } from 'react';
import Carousel from 'nuka-carousel';
import { renderItems } from './Items/Items.jsx';
import { useOutletContext } from 'react-router-dom';
import useEventFeed from '../../hooks/query/useEventFeed.js';

const Slideshow = () => {
  const { event } = useOutletContext();
  const { id, items } = event?.data || {};
  const [allItems, setAllItems] = useState(items || []);
  const [slideIndex, setSlideIndex] = useState(0);
  const events = useEventFeed(id);
  const afterSlideChanged = index => setSlideIndex(index)

  if (events.isLoading) {
    return <div>LOADING...</div>
  }

  events.data.onmessage = ({ data }) => {
    const newItems = JSON.parse(data);
    if (newItems.type === 'INITIALIZATION') setAllItems(newItems.data);
    else setAllItems(state => [...state, newItems.data])
  };

  return (
    <Carousel
      pauseOnHover={false}
      vertical
      withoutControls
      autoplay={allItems?.length > 1}
      wrapAround
      autoplayInterval={8000}
      dragging={false}
      swiping={false}
      slideIndex={slideIndex}
      afterSlide={afterSlideChanged}
    >
      {renderItems({ items: allItems })}
    </Carousel>
  )
};

export default Slideshow;
import React from 'react';
import logo from './img/logo.jpeg';

const ComingSoon = () => {
  return (
    <>
      <div style={{ 'margin': '50px auto 0', textAlign: 'center' }}>
        <img src={logo} alt="Hooorah" style={{ width: 500, height: 'auto'}} />
      </div>
      <h1 style={{ 'textAlign': 'center' }}>Coming soon...</h1>
    </>
  )
};

export default ComingSoon;
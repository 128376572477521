import React from 'react';
import { Link } from "react-router-dom";
import GoogleLogin from './components/Login/GoogleLogin.jsx';
import { useAuth } from './context/Auth.context.jsx';
import useLogin from './hooks/query/useLogin.js';
import useEvents from './hooks/query/useEvents.js';

const App = () => {
  useLogin({ email: 'lapides.daniel@gmail.com', password: '1234' });
  const { userData } = useAuth();
  const events = useEvents({ enabled: !!userData && !!Object.keys(userData).length })

  const onCallback = async (res) => {
    const { tokenId } = res;
    if (tokenId) {
      console.log('TOKEN ID', tokenId);
    }
  };

  return (
    <>
      <GoogleLogin onCallback={onCallback} />
      <div>{JSON.stringify(userData)}</div>
      <div>{events?.data?.map(event => (
        <div><Link to={`/app/event/${event.code}`}>{event.name}</Link></div>
      ))}</div>
    </>
  );
};

export default App;
import React, { useContext, useState, createContext } from 'react';
import PropTypes from 'prop-types';

const authContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [accessToken, setAccessToken] = useState();

  const providedValue = {
    userData,
    accessToken,
    setUserData,
    setAccessToken
  };
  return <authContext.Provider value={providedValue}>{children}</authContext.Provider>;
};

const useAuth = () => useContext(authContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AuthProvider, useAuth };

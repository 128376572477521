import axios from 'axios';

const ACCESS_TOKEN_KEY = 'x-hooorah-access-token';

class Communication {

  static constants = { ACCESS_TOKEN_KEY }

  constructor() {
    this.accessToken = undefined;
    this.baseURL = process.env.NODE_ENV === 'prod'
      ? 'https://api.hooorah.co/api/v1'
      : 'http://localhost:4000/api/v1';

    this.instance = axios.create({
      baseURL: this.baseURL,
      timeout: 60000,
      withCredentials: true
    });
  }

  eventSource(url) {
    return new EventSource(`${this.baseURL}${url}`, { withCredentials: true });
  }
}

const CommunicationInstance = new Communication();
export default CommunicationInstance

export const POST = async (url, body, opts) => CommunicationInstance.instance.post(url, body, opts);
export const PUT = async (url, body) => CommunicationInstance.instance.put(url, body);
export const GET = async (url, opts) => CommunicationInstance.instance.get(url, opts);
export const DELETE = async url => CommunicationInstance.instance.delete(url);
export const SOURCE = url => CommunicationInstance.eventSource(url);
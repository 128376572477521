import React from 'react';
import { Outlet, useParams } from "react-router-dom";
import useEvent from '../../hooks/query/useEvent';

function Event() {
  const params = useParams();
  const event = useEvent({ code: params.eventCode });
  if (event.isLoading) return <div>LOADING...</div>;
  return <Outlet context={{ event }} />;
}

export default Event;
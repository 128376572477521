import { useQuery } from 'react-query';
import { login } from '../../api/auth';
import { useAuth } from '../../context/Auth.context.jsx';

export default function useLogin(data, enabled = true) {
  const res = useQuery('login', () => login(data), {
    staleTime: Infinity,
    cacheTime: Infinity,
    select: res => res?.data?.data,
    enabled
  });
  const { setUserData } = useAuth();
  setUserData(res?.data);
  return res;
}

/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Twemoji } from 'react-emoji-render';

const Items = (props) => {
    return <div className="items">{renderItems(props)}</div>;
};

export const renderItems = ({ items }) => {
    return items?.map(item => {
        if (!item.message && !item.image) return null;
        return (
            <div key={item.id} className="item">
                <div className="item-meta">
                    <div className="item-meta-author">
                        <img src={item?.author?.picture} height="30" width="30" />
                        <span> {item?.author?.name || "Daniel Lapides"}</span>
                    </div>
                    <small className="item-meta-platform">{item.platform}</small>
                </div>
                {item.image && (
                    <div className="item-attachment">
                        <img src={item.image} with={60} height={60} />
                    </div>
                )}
                {item.message && <div className="item-text"><Twemoji text={item.message} /></div>}
            </div>
        )
    });
};

export default Items;
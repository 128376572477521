import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import Items from './Items/Items.jsx';

const ItemsList = () => {
  const { event } = useOutletContext();
  if (!event?.data?.items?.length) {
    return <div>NO ITEMS</div>;
  }
  return (
    <>
      <Link to={`/app/event/${event.data.code}/slideshow`}>Open slideshow</Link>
      <Items items={event?.data?.items} />
    </>
  );
};

export default ItemsList;
import { useQuery } from 'react-query';
import { getEvents } from '../../api/events';

export default function useEvents(opts) {
  return useQuery('get-events', () => getEvents(), {
    refetchOnWindowFocus: true,
    ...(opts || {}),
    select: res => res?.data?.data
  });
}
